<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="loaded"
    :extra-validation="extraValidation"
    :extra-actions="extraActions"
  >
    <b-card v-if="loaded">
      <b-tabs v-model="activeTabIndex" pills>
        <!-- Tab: Account -->
        <b-tab v-if="!editPriceOnly" active>
          <template #title>
            <feather-icon icon="ArchiveIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="infoFields"
            allow-autofocus
          />
        </b-tab>

        <!-- Tab: Inner Page -->
        <b-tab v-if="!editPriceOnly">
          <template #title>
            <feather-icon icon="LayoutIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Layout') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="layoutFields.header"
          />

          <LayoutPreview layout="product" />
        </b-tab>

        <!-- Tab: Categories -->
        <b-tab v-if="!editPriceOnly">
          <template #title>
            <feather-icon icon="ArchiveIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Categories') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="categoryFormFields"
            allow-autofocus
          />
        </b-tab>

        <!-- Tab: Prices -->
        <b-tab
          v-if="isSeller || (isAdmin && entity.id > 0) || isDuplicate"
          :lazy="!isSeller"
        >
          <template #title>
            <feather-icon
              icon="ShoppingCartIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t('Prices') }}</span>
          </template>
          <EntityEditSection
            v-if="isSeller"
            :module="config.module"
            :entity.sync="entity"
            :form-fields="priceFields"
          />
          <ProductPricesList
            v-if="isAdmin"
            :module="config.module"
            :parent-entity.sync="entity"
          />
        </b-tab>

        <!-- Tab: Options -->
        <b-tab v-if="false && (entity.id > 0 || isDuplicate)">
          <template #title>
            <feather-icon icon="PackageIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Options') }}</span>
          </template>
          <b-row>
            <b-col cols="12">
              <RepeaterField
                :module="config.module"
                :title="$t('Option')"
                :field="entity.productOptions"
                :form-fields="optionFormFields"
              />
            </b-col>
          </b-row>
        </b-tab>

        <!-- Tab: supplier admin logo  -->
        <b-tab v-if="isAdmin">
          <template #title>
            <feather-icon icon="LayoutIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Supplier Logo') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="supplierLogoFields"
            allow-autofocus
          />
          <LayoutPreview layout="product" />
        </b-tab>
      </b-tabs>
    </b-card>

    <!-- Layout Tab -->
    <template v-if="!editPriceOnly && loaded && activeTabIndex === 1">
      <ProductLayoutEdit
        :title="$t('Zone1')"
        :module="config.module"
        :entity.sync="entity"
        :form-fields="layoutFields.zone1"
        required-section
      />

      <ProductLayoutEdit
        :title="$t('Zone2')"
        :module="config.module"
        :entity.sync="entity"
        :form-fields="layoutFields.zone2"
        :multi-grid-columns="[7, 5]"
       
        use-multi-grid
      />

      <ProductLayoutEdit
        :title="$t('Zone3')"
        :module="config.module"
        :entity.sync="entity"
        :form-fields="layoutFields.zone3"
      />

      <ProductLayoutEdit
        :title="$t('Zone4')"
        :module="config.module"
        :entity.sync="entity"
        :form-fields="layoutFields.zone4"
        :multi-grid-columns="[6, 6]"
        use-multi-grid
      />

      <ProductLayoutEdit
        :module="config.module"
        :entity.sync="entity"
        :form-fields="layoutFields.footer"
      />

      <div class="d-flex align-center justify-content-start mb-1">
        <div class="section-image">
          <img src="@/assets/images/icons/section-valid.png" />
        </div>

        <p class="ml-1">
          {{ $t('ZoneComplete') }}
        </p>
      </div>

      <div class="d-flex align-center justify-content-start">
        <div class="section-image">
          <img src="@/assets/images/icons/section-invalid.png" />
        </div>

        <p class="ml-1" v-html="$t('ZonePending')" />
      </div>
    </template>
  </EntitySingle>
</template>

<script>
import { BRow, BCol, BTab, BTabs, BCard, BAvatar } from 'bootstrap-vue'

import {
  getUserRights,
  isAdmin,
  isSeller,
  isSupplier,
  canEditPrice,
  canPublishToShop,
  canUnPublishFromShop,
} from '@/auth/utils'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import RepeaterField from '@/layouts/entity/RepeaterField.vue'
import LayoutPreview from '@/layouts/entity/LayoutPreview.vue'
import ProductLayoutEdit from '@/components/ProductLayout/ProductLayoutEdit.vue'
import ProductPricesList from './ProductPricesList.vue'
import axios from '@/libs/axios'

import storeModule from '../productsStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAvatar,
    EntitySingle,
    EntityEditSection,
    ProductLayoutEdit,
    LayoutPreview,
    RepeaterField,
    ProductPricesList,
  },
  data() {
    return {
      activeTabIndex: 0,
      entity: {
        productPrice: {},
        productOptions: [],
        productGalleries: [],
        category: '',
        subCategory: '',
      },
      loaded: false,
      // categoryOptions: [],
      styleOptions: [],
      sellerOptions: [],
      supplierOptions: [],
      colorOptions: [],
      tagsOptions: [],
      shippingClassOptions: [],
      categoryListOptions: [],
      subCategoryOptions: [],
      attributeOptions: [],
      subAttributeOptions: {},
      initialLoadCategory: true,
      initialLoadSubCategory: true,
    }
  },
  computed: {
    isDuplicate() {
      return this.$route.params.duplicate
    },
    rights() {
      return getUserRights()
    },
    
    isAdmin() {
      return isAdmin()
    },
    isSupplier() {
      return isSupplier()
    },
    isSeller() {
      return isSeller()
    },
    canEditPrice() {
      return canEditPrice()
    },
    editPriceOnly() {
      //console.log('########'+this.entity.supplier);
      //console.log(this.entity.supplier);
      return isSeller() && this.entity.supplier
    },
    entityProdCategory() {
      return this.entity.category && this.entity.category.id
    },
    entityProdSubCategory() {
      return this.entity.subCategory && this.entity.subCategory.id
    },

    config() {
      return {
        store: storeModule,
        module: 'products',
        endpoint: 'products',
        route: 'products',
        title: {
          single: this.$t('Product'),
          plural: this.$t('Products'),
        },
      }
    },
    supplierLogoFields() {
      return [
        {
          id: 'logo',
          type: 'cropupload',
          label: `${this.$t('Logo')} (512x512)`,
          colSize: 6,
          width: 512,
          height: 512,
          autoupload: true,
          required: false,
        },
      ]
    },
    infoFields() {
      const fields = [
        {
          id: 'eshopId',
          type: 'hidden',
          hidden: true,
        },
        {
          id: 'featuredImg',
          type: 'cropupload',
          label: `${this.$t('Featured Image')} A (Min Width:420 X Min Height:420)`,
          colSize: 6,
          minWidth: 420,
          minHeight: 420,
          autoupload: true,
          required: true,
        },
        {
          id: 'featuredImgB',
          type: 'cropupload',
          label: `${this.$t('Featured Image')} B (Min Width:420 X Min Height:420)`,
          colSize: 6,
          minWidth: 420,
          minHeight: 420,
          autoupload: true,
          required: true,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('name'),
          required: true,
          colSize: 6,
        },
        {
          id: 'code',
          type: 'text',
          label: this.$t('code'),
          required: true,
          colSize: 3,
        },
        {
          id: 'youtubeLink',
          type: 'text',
          label: this.$t('youtube'),
          required: false,
          colSize: 3,
        },
        // {
        //   id: 'isOnline',
        //   type: 'checkbox',
        //   label: this.$t('isOnline'),
        //   colSize: 3,
        // },
        // {
        //   id: 'enabled',
        //   type: 'checkbox',
        //   label: this.$t('enabled'),
        //   colSize: 3,
        // },
        // {
        //   id: 'productCategories',
        //   type: 'selectmultiple',
        //   label: this.$t('product category'),
        //   options: this.categoryOptions,
        //   colSize: 5,
        // },
        {
          id: 'tags',
          type: 'selectmultiple',
          label: this.$t('Product Tags'),
          options: this.tagsOptions,
          colSize: 5,
        },
        {
          id: 'productStyles',
          type: 'selectmultiple',
          label: this.$t('Product Styles'),
          options: this.styleOptions,
          colSize: 5,
          readonly: !this.isAdmin,
        },
        {
          id: 'hasStock',
          type: 'checkbox',
          label: this.$t('Has Stock'),
          colSize: 1,
        },
        {
          id: 'quantity',
          type: 'text',
          label: this.$t('quantity'),
          required: false,
          rules: 'integer',
          colSize: 1,
          dependency: 'hasStock',
        },
        {
          id: 'customized',
          type: 'checkbox',
          label: this.$t('Customized'),
          colSize: 1,
        },
        {
          id: 'shippingClass',
          object: true,
          type: 'select',
          label: this.$t('Shipping Class'),
          options: this.shippingClassOptions,
          required: false,
          colSize: 2,
        },
        {
          id: 'targetPrice',
          type: 'text',
          label: this.$t('target price'),
          rules: 'positive',
          colSize: 3,
          readonly: !this.isSupplier && !this.isAdmin,
        },
        {
          id: 'minPrice',
          type: 'text',
          label: this.$t('min price'),
          rules: 'positive',
          colSize: 3,
          readonly: !this.isSupplier && !this.isAdmin,
        },
        {
          id: 'maxPrice',
          type: 'text',
          label: this.$t('max price'),
          rules: 'positive',
          colSize: 3,
          readonly: !this.isSupplier && !this.isAdmin,
        },
        {
          id: 'length',
          type: 'text',
          label: this.$t('length'),
          required: true,
          rules: 'positive',
          colSize: 3,
        },
        {
          id: 'width',
          type: 'text',
          label: this.$t('width'),
          required: true,
          rules: 'positive',
          colSize: 3,
        },
        {
          id: 'height',
          type: 'text',
          label: this.$t('height'),
          required: true,
          rules: 'positive',
          colSize: 3,
        },
        {
          id: 'weight',
          type: 'text',
          label: this.$t('weight'),
          rules: 'positive',
          colSize: 3,
        },
        {
          id: 'productColors',
          object: true,
          type: 'select',
          label: this.$t('Color'),
          options: this.colorOptions,
          required: false,
          colSize: 3,
        },
        // {
        //   id: 'refurbished',
        //   type: 'checkbox',
        //   label: this.$t('Refurbished'),
        //   colSize: 1,
        // },
      ]
      if (this.isAdmin) {
        fields.push({
          id: 'bestSeller',
          type: 'checkbox',
          label: this.$t('Best seller'),
          colSize: 1,
        })
      }
      if (this.isAdmin) {
        fields.push({
          id: 'supplier',
          type: 'select',
          object: true,
          label: this.$t('supplier'),
          options: this.supplierOptions,
          colSize: 6,
        })
      }
      return fields
    },

    categoryFormFields() {
      const categoryfields = [
        {
          id: 'category',
          type: 'select',
          object: true,
          label: this.$t('product category'),
          options: this.categoryListOptions,
          required: true,
          colSize: 6,
        },
        {
          id: 'subCategory',
          type: 'select',
          object: true,
          label: this.$t('productSubCategory'),
          options: this.subCategoryOptions,
          colSize: 6,
        },
      ]

      this.attributeOptions.forEach((item) => {
        categoryfields.push({
          id: item.id,
          type: 'selectmultiple',
          object: true,
          label: item.title,
          options: this.subAttributeOptions[item.id],
          dependency: 'subCategory',
          colSize: 3,
        })
      })
      return categoryfields
    },

    layoutFields() {
      return {
        header: [
          {
            id: 'subtitle',
            type: 'text',
            label: this.$t('subtitle'),
            colSize: 6,
          },
          {
            id: 'warranty',
            type: 'text',
            label: this.$t('warranty'),
            colSize: 6,
          },
        ],
        zone1: [
          {
            id: 'productGalleries',
            type: 'gallery',
            label: `${this.$t('Gallery')} (Min Width:800 X Min Height:800)`,
            colSize: 12,
            width: 800,
            height: 800,
            size: 800,
            autoupload: true,
          },
          {
            id: 'shortDescription',
            type: 'quill',
            limitedOptions: true,
            label: this.$t('shortDescription'),
            required: false,
            colSize: 12,
          },
        ],
        zone2: [
          [
            {
              id: 'content1',
              type: 'quill',
              limitedOptions: true,
              label: `${this.$t('Content')} #1`,
              layoutPreview: 'product_content_1',
              colSize: 12,
            },
            {
              id: 'image2',
              type: 'cropupload',
              label: `${this.$t('Image')} #2: (366x406)`,
              layoutPreview: 'product_image_2',
              colSize: 6,
              width: 366,
              height: 406,
              autoupload: true,
            },
            {
              id: 'image3',
              type: 'cropupload',
              label: `${this.$t('Image')} #3: (366x406)`,
              layoutPreview: 'product_image_3',
              colSize: 6,
              width: 366,
              height: 406,
              autoupload: true,
            },
            {
              id: 'content2',
              type: 'quill',
              limitedOptions: true,
              label: `${this.$t('Content')} #2`,
              layoutPreview: 'product_content_2',
              colSize: 12,
            },
          ],
          [
            {
              id: 'image1',
              type: 'cropupload',
              label: `${this.$t('Image')} #1: (535x485)`,
              layoutPreview: 'product_image_1',
              colSize: 12,
              width: 535,
              height: 485,
              autoupload: true,
            },
            {
              id: 'image4',
              type: 'cropupload',
              label: `${this.$t('Image')} #4: (535x485)`,
              layoutPreview: 'product_image_4',
              colSize: 12,
              width: 535,
              height: 485,
              autoupload: true,
            },
          ],
        ],
        zone3: [
          {
            id: 'image5',
            type: 'cropupload',
            label: `${this.$t('Image')} #5: (1920x800)`,
            layoutPreview: 'product_image_5',
            colSize: 12,
            width: 1920,
            height: 800,
            autoupload: true,
          },
        ],
        zone4: [
          [
            {
              id: 'image6',
              type: 'cropupload',
              label: `${this.$t('Image')} #6: (648x595)`,
              layoutPreview: 'product_image_6',
              colSize: 12,
              width: 648,
              height: 595,
              autoupload: true,
            },
            {
              id: 'content4',
              type: 'quill',
              limitedOptions: true,
              label: `${this.$t('Content')} #4`,
              layoutPreview: 'product_content_4',
              colSize: 12,
            },
            {
              id: 'image8',
              type: 'cropupload',
              label: `${this.$t('Image')} #8: (309x417)`,
              layoutPreview: 'product_image_8',
              colSize: 6,
              width: 309,
              height: 417,
              autoupload: true,
            },
            {
              id: 'image9',
              type: 'cropupload',
              label: `${this.$t('Image')} #9: (309x417)`,
              layoutPreview: 'product_image_9',
              colSize: 6,
              width: 309,
              height: 417,
              autoupload: true,
            },
          ],
          [
            {
              id: 'content3',
              type: 'quill',
              limitedOptions: true,
              label: `${this.$t('Content')} #3`,
              layoutPreview: 'product_content_3',
              colSize: 12,
            },
            {
              id: 'image7',
              type: 'cropupload',
              label: `${this.$t('Image')} #7: (648x969)`,
              layoutPreview: 'product_image_7',
              colSize: 12,
              width: 648,
              height: 969,
              autoupload: true,
            },
          ],
        ],
        footer: [
          {
            id: 'epilogue',
            type: 'quill',
            limitedOptions: true,
            label: this.$t('epilogue'),
            layoutPreview: 'product_epilogue',
            colSize: 8,
          },
          {
            id: 'generalDescription',
            type: 'quill',
            limitedOptions: true,
            label: this.$t('generalDescription'),
            layoutPreview: 'product_general_description',
            colSize: 8,
          },
        ],
      }
    },
    galleryFormFields() {
      return [
        {
          id: 'img',
          type: 'image',
          label: this.$t('Featured Image'),
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('name'),
          required: true,
        },
      ]
    },

    optionFormFields() {
      return [
        {
          id: 'image',
          type: 'cropupload',
          label: `${this.$t('Image')}: (600x600)`,
          width: 600,
          height: 600,
          autoupload: true,
          colSize: 3,
        },
        {
          id: 'title',
          type: 'text',
          label: this.$t('name'),
          required: true,
          colSize: 3,
        },
        {
          id: 'price',
          type: 'text',
          label: this.$t('price'),
          required: true,
          rules: 'positive',
          colSize: 2,
        },
        {
          id: 'salePrice',
          type: 'text',
          label: this.$t('sale price'),
          required: false,
          rules: 'positive',
          colSize: 2,
        },
      ]
    },

    priceFields() {
      return [
        {
          id: 'minPrice',
          type: 'text',
          label: this.$t('min price'),
          rules: 'positive',
          colSize: 4,
          readonly: true,
        },
        {
          id: 'maxPrice',
          type: 'text',
          label: this.$t('max price'),
          rules: 'positive',
          colSize: 4,
          readonly: true,
        },
        {
          id: 'targetPrice',
          type: 'text',
          label: this.$t('Target Price'),
          rules: 'positive',
          colSize: 4,
          readonly: true,
        },
        {
          id: 'regularPrice',
          parent: 'productPrice',
          type: 'text',
          label: this.$t('price'),
          required: true,
          rules: 'positive',
          colSize: 6,
          readonly: !this.canEditPrice,
        },     
        {
          id: 'salePrice',
          parent: 'productPrice',
          type: 'text',
          label: this.$t('salesPrice'),
          rules: 'positive',
          colSize: 6,
          readonly: !this.canEditPrice,
        },
        {
          id: 'saleStartDate',
          parent: 'productPrice',
          type: 'date',
          label: this.$t('saleStartDate'),
          dependency: 'salePrice',
          refMaxDate: 'saleEndDate',
          colSize: 6,
          readonly: !this.canEditPrice,
        },
        {
          id: 'saleEndDate',
          parent: 'productPrice',
          type: 'date',
          label: this.$t('saleEndDate'),
          dependency: 'salePrice',
          refMinDate: 'saleStartDate',
          required: false,
          colSize: 6,
          readonly: !this.canEditPrice,
        },
        {
          id: 'deliveryReady',
          parent: 'productPrice',
          type: 'checkbox',
          label: this.$t('Ready for delivery'),
          colSize: 4,
          readonly: !this.canEditPrice,
        },
        {
          id: 'showroom',
          parent: 'productPrice',
          type: 'checkbox',
          label: this.$t('Showroom'),
          colSize: 4,
          readonly: !this.canEditPrice,
        },
        {
          id: 'constructionDays',
          parent: 'productPrice',
          type: 'text',
          label: this.$t('Shipping Lead Days'),
          rules: 'integer',
          colSize: 4,
        },
        {
          id: 'quantity',
          parent: 'productPrice',
          type: 'text',
          label: this.$t('quantity'),
          required: false,
          rules: 'integer',
          colSize: 4,
          readonly: !this.canEditPrice,
        },
      ]
    },

    extraActions() {
      const canPublish = canPublishToShop()
      const canUnPublish = canUnPublishFromShop(this.entity.supplier)
      return [
        {
          icon: 'EyeIcon',
          title: 'View in e-shop',
          href: `${process.env.VUE_APP_SHOP_URL}product/${this.entity.eshopSlug}`,
          variant: 'outline-secondary',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
        {
          endpoint: 'eshop/publish',
          icon: 'UploadCloudIcon',
          title: 'Publish to e-shop',
          renderCondition: {
            key: 'published',
            value: false,
          },
          authorityValidation: canPublish,
        },
        {
          // callback: 'updateEshop',
          endpoint: 'eshop/publish',
          icon: 'UploadCloudIcon',
          title: 'Update e-shop',
          renderCondition: {
            key: 'published',
            value: true,
          },
          authorityValidation: canPublish,
        },
        // {
        //   callback: 'updateStockPrice',
        //   endpoint: 'eshop/update-stock-price',
        //   icon: 'UploadCloudIcon',
        //   title: 'Update e-shop Stock and Price',
        //   variant: 'warning',
        //   renderCondition: {
        //     key: 'published',
        //     value: true,
        //   },
        //   authorityValidation: canPublish,
        // },
        {
          endpoint: 'eshop/change-product-status',
          icon: 'DownloadCloudIcon',
          title: 'Remove from e-shop',
          variant: 'danger',
          renderCondition: {
            key: 'published',
            value: true,
          },
          //authorityValidation: canUnPublish,
          //authorityValidation: canPublish,
        },
      ]
    },
  },
  watch: {
    attributeOptions: function () {
      this.attributeOptions.map((item) => {
        this.fetchSubAttributeList(item.id)
      })
    },
    entityProdCategory: function () {
      if (this.entity.category) {
        // console.log('Entering into entityCategory():', this.entity.category)
        if (this.initialLoadCategory !== true) {
          this.entity.subCategory = ''
          Object.entries(this.entity).forEach((item) => {
            if (!isNaN(parseInt(item[0]))) {
              delete this.entity[item[0]]
            }
          })
        }
        this.subCategoryOptions = []
        let categoryId = this.entity.category.id
        if (categoryId != '') {
          axios
            .get('categories/list/all-sub-categories', {
              params: { categoryId },
            })
            .then((response) => {
              this.subCategoryOptions = response.data
              if (this.subCategoryOptions.length == 0)
                this.attributeOptions = this.subAttributeOptions = []
            })
        }
        this.initialLoadCategory = false
      } else {
        // console.log('else block entityProdCategory()')
        this.entity.subCategory = ''
        this.subCategoryOptions = this.attributeOptions = []
      }
    },
    entityProdSubCategory: function () {
      if (this.entity.subCategory) {
        // console.log(
        //   'Entering into entitySubCategory():',
        //   this.entity.subCategory
        // )
        if (this.initialLoadSubCategory !== true) {
          Object.entries(this.entity).forEach((item) => {
            if (!isNaN(parseInt(item[0]))) {
              delete this.entity[item[0]]
            }
          })
        }
        this.attributeOptions = []
        let categoryId = this.entity.subCategory.id
        if (categoryId != '') {
          axios
            .get('categories/list/attributes', {
              params: { categoryId },
            })
            .then((response) => {
              this.attributeOptions = response.data
              if (this.attributeOptions.length == 0)
                this.subAttributeOptions = []
            })
        }
        this.initialLoadSubCategory = false
      } else {
        // console.log('else block entityProdSubCategory()')
        this.attributeOptions = this.subAttributeOptions = []
      }
    },
  },
  mounted() {
    // this.$store.dispatch('app-common/fetchCategories').then((response) => {
    //   this.categoryOptions = response.data
    // })
    this.$store.dispatch('app-common/fetchDecorStyles').then((response) => {
      this.styleOptions = response.data
    })
    this.$store.dispatch('app-common/fetchSellers').then((response) => {
      this.sellerOptions = response.data
    })
    this.$store.dispatch('app-common/fetchSuppliers').then((response) => {
      this.supplierOptions = response.data
    })
    this.$store.dispatch('app-common/fetchTags').then((response) => {
      this.tagsOptions = response.data
    })
    this.$store.dispatch('app-common/fetchShippingClasses').then((response) => {
      this.shippingClassOptions = response.data
    })
    this.$store.dispatch('app-common/fetchColorOptions').then((response) => {
      this.colorOptions = response.data
    })
    axios.get('categories/list/all-categories').then((response) => {
      this.categoryListOptions = response.data
    })
  },

  methods: {
    extraValidation() {
      if (
        this.entity.minPrice > 0 &&
        this.entity.maxPrice > 0 &&
        this.entity.minPrice > this.entity.maxPrice
      ) {
        return this.$t('min price cannot be larger than max')
      }
      return ''
    },
    fetchSubAttributeList(id) {
      axios
        .get('categories/list/sub-attributes', {
          params: { attributeId: id },
        })
        .then((response) => {
          this.subAttributeOptions[id] = response.data
          this.subAttributeOptions = { ...this.subAttributeOptions }
        })
    },
  },
}
</script>
<style scoped>
.section-image img {
  width: 40px;
  height: auto;
}
</style>
