<template>
  <div>
    <EntityList
      ref="companyPaymentsList"
      :config="config"
      :columns="columns"
      :custom-query="{product: parentEntity.id}"
      :formatted-columns="formattedColumns"
      :form-fields="formFields"
      authority="Products_Product"
      embedded
    />
  </div>
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import {
  isAdmin, isSeller, isSupplier, canEditPrice, getCompanyId,
} from '@/auth/utils'
import storeModule from '../productPricesStoreModule'

export default {
  components: {
    EntityList,
  },
  props: {
    module: {
      type: String,
      required: true,
    },
    parentEntity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sellerOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'product-prices',
        endpoint: 'product-prices',
        route: 'product-prices',
        title: {
          single: this.$t('Price Add'),
          plural: this.$t('Prices'),
        },
      }
    },
    isAdmin() {
      return isAdmin()
    },
    isSupplier() {
      return isSupplier()
    },
    isSeller() {
      return isSeller()
    },
    canEditPrice() {
      return canEditPrice()
    },
    companyId() {
      return getCompanyId()
    },
    columns() {
      const columns = [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'regularPrice', label: this.$t('price'), sortable: true },
        { key: 'salePrice', label: this.$t('salesPrice'), sortable: true },
        { key: 'customColor', label: this.$t('customColor'), sortable: true },
        { key: 'customDimension', label: this.$t('customDimension'), sortable: true },
        { key: 'customFabric', label: this.$t('customFabric'), sortable: true },
      ]
      if (!this.isSeller) {
        columns.push({ key: 'seller.title', label: this.$t('seller'), sortable: false })
      }
      return columns.concat([
        { key: 'quantity', label: this.$t('quantity'), sortable: true },
        { key: 'deliveryReady', label: this.$t('Ready for delivery'), sortable: false },
        { key: 'showroom', label: this.$t('Showroom'), sortable: false },
        { key: 'actions', label: this.$t('actions') },
      ])
    },
    formattedColumns() {
      return [
        { key: 'deliveryReady', type: 'boolean' },
        { key: 'showroom', type: 'boolean' },
      ]
    },
    formFields() {
      const fields = [
        {
          id: 'productId',
          type: 'hidden',
          initVal: this.parentEntity.id,
        },
        {
          id: 'regularPrice',
          type: 'text',
          label: this.$t('price'),
          required: false,
          rules: 'positive',
          colSize: 6,
          readonly: !this.canEditPrice,
        },
        {
          id: 'salePrice',
          type: 'text',
          label: this.$t('salesPrice'),
          rules: 'positive',
          colSize: 6,
          readonly: !this.canEditPrice,
        },
        {
          id: 'saleStartDate',
          type: 'date',
          label: this.$t('saleStartDate'),
          dependency: 'salePrice',
          refMaxDate: 'saleEndDate',
          colSize: 6,
          readonly: !this.canEditPrice,
        },
        {
          id: 'saleEndDate',
          type: 'date',
          label: this.$t('saleEndDate'),
          dependency: 'salePrice',
          refMinDate: 'saleStartDate',
          required: false,
          colSize: 6,
          readonly: !this.canEditPrice,
        },
        {
          id: 'quantity',
          type: 'text',
          label: this.$t('quantity'),
          required: false,
          rules: 'integer',
          colSize: 6,
        },
        {
          id: 'constructionDays',
          type: 'text',
          label: this.$t('Shipping Lead Days'),
          rules: 'integer',
          colSize: 6,
        },
        {
          id: 'deliveryReady',
          type: 'checkbox',
          label: this.$t('Ready for delivery'),
          colSize: 6,
        },
        {
          id: 'showroom',
          type: 'checkbox',
          label: this.$t('Showroom'),
          colSize: 6,
        },
        {
          id: 'customColor',
          type: 'text',
          label: this.$t('Custom Color'),
          colSize: 6,
          //placeholder: 'Custom Color',
          // rules: 'positive',
          // readonly: !this.canEditPrice,
        },
        {
          id: 'customDimension',
          type: 'text',
          label: this.$t('Custom Dimension'),
          colSize: 6,
          placeholder: '',
          // rules: 'positive',
          // readonly: !this.canEditPrice,
        },
        {
          id: 'customFabric',
          type: 'text',
          label: this.$t('Custom Fabric'),
          colSize: 6,
          placeholder: '',
          // rules: 'positive',
          // readonly: !this.canEditPrice,
        },
      ]
      if (this.isSeller) {
        fields.push({
          id: 'seller',
          type: 'hidden',
          initVal: { id: this.companyId },
        })
      } else {
        fields.push({
          id: 'seller',
          object: true,
          type: 'select',
          label: this.$i18n.t('Seller'),
          options: this.sellerOptions,
          required: true,
        })
      }
      return fields
    },
  },
  mounted() {
    if (!this.isSeller) {
      this.$store.dispatch('app-common/fetchSellers')
        .then(response => {
          this.sellerOptions = response.data
        })
    }
  },
}
</script>
<style>
#customColor::placeholder,#customDimension::placeholder,#customFabric::placeholder{
  color: transparent;
}

#customColor:focus::placeholder {
  color: transparent;
}
#customColor:focus:visible::placeholder {
  color: transparent;
}

#customColor:focus-visible {
  text-shadow:none;
}
#customDimension:focus-visible {
  text-shadow:none;
}
#customFabric:focus-visible {
  text-shadow:none;
}
</style>